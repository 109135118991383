import { htmlPOST } from '../lib/html-methods';
import { isDefined } from '../lib/util';

export async function loginAndGetJwtToken(token : string) : Promise<string> {
    return htmlPOST("https://skybert.statped.no/api/identity/auth0/login", {"accessToken" : token}, "", "application/x-www-form-urlencoded")
    .then(response => {
    	if (response.status === 200) {
    		return response.json();
    	}
    	else {
    		throw new Error("Got status code " + response.status.toString());
    	}
    })
    .then(responseData => {
    	if (isDefined(responseData) && isDefined(responseData.jwt) && responseData.jwt !== "") {
    		return responseData.jwt;
    	}
    	else {
    		throw new Error("loginResponse error");
    	}
    });
}
