import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { createBrowserHistory } from 'history';
import './App.css';
import Media from './components/Media';
import Login from './components/Login';
import PhotoPreview from './components/PhotoPreview';
import UploadView from './components/UploadView';
import RecordingPreview from './components/RecordingPreview';

export const history = createBrowserHistory();

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component, {
  })} {...args} />
);

function App() {
  return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <ProtectedRoute exact path="/media" component={Media} />
          <ProtectedRoute exact path="/preview/photo" component={PhotoPreview} />
          <ProtectedRoute exact path="/preview/recording" component={RecordingPreview} />
          <ProtectedRoute exact path="/upload" component={UploadView} />
        </Switch>
      </Router>
  );
}

export default App;
