import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { capitalize } from '@material-ui/core';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { BiUpload } from 'react-icons/bi';
import { MdClear } from 'react-icons/md';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useAuth0 } from '@auth0/auth0-react';
import { removeData, uploadButton } from '../lib/util';
import { Store } from '../store/Store';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    previewRoot: {
        flexGrow: 1,
    },
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
    },
    fabUpload: {
        position: 'absolute',
        zIndex: 1,
        top: -40,
        left: 'auto',
        right: 15,
        margin: '0 auto',
    }
}),);

function RecordingPreview() {
    const classes = useStyles();
    const history = useHistory();
    const [filename, setFilename] = useState<string>("");
    const [dataSize, setDataSize] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [type, setType] = useState<string>("");
    const [isRemoving, setIsRemoving] = useState<boolean>(false);

    const { state, dispatch } = useContext(Store);
    const { semaphore } = state;

    const {
        getAccessTokenSilently
    } = useAuth0();

    useEffect(() => {
        let dbObject : Object = history.location.state as Object
        setFilename(dbObject['filename']);
        setType(dbObject['type']);
        setDataSize(dbObject['dataSize']);
        setDuration(dbObject['duration']);
    }, [history.location.state]);

    return (
        <div className={classes.previewRoot}>
            <React.Fragment>
                <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: '100vh' }}>
                    <Typography color="textPrimary">{capitalize(type)}</Typography>
                    <Typography color="textPrimary">{filename}</Typography>
                    <Typography color="textPrimary">{'Duration: ' + (new Date(Math.round((duration / 1000) * 1000)).toISOString().substr(11, 8)).toString()}</Typography>
                    <Typography color="textPrimary">{'Size: ' + ((dataSize / (1024 * 1024)).toFixed(2)).toString() + ' MB'}</Typography>
                </Grid>
                <AppBar position="fixed" color="inherit" className={classes.bottomAppBar}>
                    <Toolbar variant="dense">
                        <Grid container>
                            <Grid container direction="row" justify="space-between">
                                <Button variant="outlined" color="secondary" disabled={isRemoving} onClick={() => {setIsRemoving(true); removeData(history)}} size="small" endIcon={isRemoving ? <CircularProgress color="secondary" size="0.7em" /> : <MdClear />}>Remove</Button>
                                <Button variant="contained" color="secondary" disabled={isRemoving} onClick={() => uploadButton(history, semaphore, getAccessTokenSilently, dispatch)} size="small" endIcon={<BiUpload />}>Upload</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        </div>
    );
}

export default RecordingPreview;
