import { Semaphore } from "await-semaphore";

export interface IState {
    captureMode : string;
    previousCaptureMode : string;
    semaphore : Semaphore;
    uploadViewUpdate : boolean;
    isUploading : boolean;
}

interface ISetCaptureMode {
    type : 'SET_CAPTURE_MODE';
    payload : string;
}

interface ISetPreviousCaptureMode {
    type : 'SET_PREVIOUS_CAPTURE_MODE';
    payload : string;
}

interface IGetSemaphore {
    type : 'GET_SEMAPHORE';
}

interface ISetUploadView {
    type : 'TOGGLE_UPLOAD_VIEW';
    payload : boolean;
}

interface IIsUploading {
    type : 'SET_IS_UPLOADING';
    payload : boolean;
}
export type Actions = ISetCaptureMode | ISetPreviousCaptureMode | IGetSemaphore | ISetUploadView | IIsUploading;

export const initialState: IState = {
    captureMode: 'photo',
    previousCaptureMode: 'init',
    semaphore: new Semaphore(1),
    uploadViewUpdate: false,
    isUploading: false,
};

export const Reducer = (state : IState, action : Actions) => {
    switch(action.type) {
        case 'SET_CAPTURE_MODE':
            return {
                ...state,
                captureMode: action.payload
            }
        case 'SET_PREVIOUS_CAPTURE_MODE':
            return {
                ...state,
                previousCaptureMode: action.payload
            }
        case 'GET_SEMAPHORE':
            return {
                ...state
            }
        case 'TOGGLE_UPLOAD_VIEW':
            return {
                ...state,
                uploadViewUpdate: !state.uploadViewUpdate
            }
        case 'SET_IS_UPLOADING':
            return {
                ...state,
                isUploading: action.payload
            }
    }
}
