import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App, { history }  from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { StoreProvider } from './store/Store';
import { authConfig } from './config';

const theme = createMuiTheme({
  palette: {
      primary: {
          main: '#81407d',
      },
      secondary: {
          main: '#e44060',
      },
  },
});

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || window.location.pathname);
}

(async () => {
  let config;
  try {
    config = await authConfig();
  } catch (e) {
    document.writeln('Could not load the configuration. Please try again later.');
    document.writeln('If the problem persists, contact the site administrator.');
    throw e;
  }

  return ReactDOM.render(
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      redirectUri={window.location.origin + '/media'}
      onRedirectCallback={onRedirectCallback}
    >
      <ThemeProvider theme={theme}>
        <StoreProvider>
          <App />
        </StoreProvider>
      </ThemeProvider>
    </Auth0Provider>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
