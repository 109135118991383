import React, { useState, useEffect, useContext } from 'react';
import List from '@material-ui/core/List';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { MdMicNone } from 'react-icons/md'
import { BiCamera, BiCameraMovie, BiUpload } from 'react-icons/bi';
import LogoutButton from '../auth/logout-button';
import { useHistory } from 'react-router-dom';
import { Store } from '../store/Store';
import { dbGetAllElements, toMedia } from '../lib/util';
import UploadList from './UploadList';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },bottomAppBar: {
            top: 'auto',
            bottom: 0,
        },
}),);

function renderDBItemList(dbItems : Array<Object>, setDBItems : Function) {
    return (
        <UploadList data={dbItems} setDBItems={setDBItems}/>
    );
}

function UploadView() {
    const classes = useStyles();
    const history = useHistory();

    const { state, dispatch } = useContext(Store);
    const { uploadViewUpdate } = state;

    const [dbItems, setDBItems] = useState<Array<Object>>([]);

    useEffect(() => {
        var captures = dbGetAllElements();
        captures.then(result => {
            setDBItems(result);
        })
    }, [uploadViewUpdate])

    function handleNavigationClick(event : any) {
        let captureType : string = event.currentTarget.id;
        toMedia(captureType, 'upload', history, dispatch);
    }

    return (
        <div className={classes.root}>
            <AppBar position="sticky" color="inherit">
                <Toolbar variant="dense">
                    <Grid container justify="space-between">
                        <LogoutButton/>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Grid container direction="row" style={{ minHeight: '100vh'}}>
                <List dense={true} style={{width: '100%'}}>
                    {renderDBItemList(dbItems, setDBItems)}
                </List>
            </Grid>

            <AppBar position="sticky" color="inherit" className={classes.bottomAppBar}>
                <Toolbar variant="dense">
                    <Grid container>
                        <Grid container direction="row" justify="space-evenly" alignItems="center" style={{paddingBottom: 5}}>
                        </Grid>
                        <Grid container direction="row" justify="space-around">
                            <Button variant="outlined" color="primary" size="large" onClick={handleNavigationClick} id="photo"><BiCamera/></Button>
                            <Button variant="outlined" color="primary" size="large" onClick={handleNavigationClick} id="video"><BiCameraMovie /></Button>
                            <Button variant="outlined" color="primary" size="large" onClick={handleNavigationClick} id="audio"><MdMicNone /></Button>
                            <Button variant="contained" color="secondary" size="large"><BiUpload /></Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default UploadView;
