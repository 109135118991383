import React from 'react';
import Grid from '@material-ui/core/Grid';
import LoginButton from '../auth/login-button';

function Login(props) {
    return (
        <div>
            <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: '100vh', background: "#e0e0e0" }}>
                <div><img id="logo" alt="Statped logo" src={require('../assets/statped-logo.png')} /></div>
                <div><LoginButton disabled={props.isLoading} /></div>
            </Grid>
        </div>
        );
}

export default Login;
