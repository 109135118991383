
export async function htmlPOST(url : string, data : {}, authorization : string = '', contentType = 'application/json') {

    let headersData = {
        'Content-Type': contentType,
        'Accept' : 'application/json'
    };

    let processedData = (contentType === 'application/json') ? JSON.stringify(data) : new URLSearchParams(data);

    if (authorization !== '') {
        headersData['Authorization'] = authorization;
    }

    const response = await fetch(url, {
        method: 'POST',
        //mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: headersData,
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: processedData // body data type must match "Content-Type" header
    });
    return response;
}

export async function htmlGET(url : string = '', authorization : string = '') {
    let headersData = {'Content-Type': 'application/json'};

    if (authorization !== '') {
        headersData['Authorization'] = authorization;
    }

    const response = await fetch(url, {
        method: 'GET',
        //mode: 'no-cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: headersData,
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        //body: data // body data type must match "Content-Type" header
    });
    return response;
}

export async function htmlPUT(url : string, data : Blob, authorization : string = '', contentType = 'application/json') {
    let headersData = {
        'Content-Type': contentType,
        'Accept' : 'application/json'
    };

    if (authorization !== '') {
        headersData['Authorization'] = authorization;
    }

    const response = await fetch(url, {
        method: 'PUT',
        //mode: 'cors', // no-cors, *cors, same-origin
        //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers: headersData,
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: data // body data type must match "Content-Type" header
    });
    return response;
}
