import React, { useState, useContext } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { MdMicNone } from 'react-icons/md'
import { BiCamera, BiCameraMovie, BiUpload } from 'react-icons/bi';
import { MdClear, MdDone } from 'react-icons/md'
import { capitalize } from '@material-ui/core'
import { Store } from '../store/Store';
import { dbDeleteElements, upload } from '../lib/util';
import { useAuth0 } from '@auth0/auth0-react';
import { Semaphore } from 'await-semaphore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listIconUploaded: {
            backgroundColor: "#81407d"
        },listIconPending: {
            backgroundColor: "#e0e0e0"
        },listIconError: {
            backgroundColor: "#d33f2f"
        },listIconUploading: {
            backgroundColor: "#e44060"
        },
}),);

function isItemDisabled(status : string) : boolean {
    return (status === 'uploading' || status === 'uploaded')
}

function renderIconBackground(classes : any, status : string) : string {
    if (status === 'uploaded') {
        return classes.listIconUploaded
    } else if (status === 'pending') {
        return classes.listIconPending
    } else if (status === 'uploading') {
        return classes.listIconUploading
    } else {
        return classes.listIconError
    }
}

function renderEndIcon(status : string) {
    if (status === 'uploaded') {
        return <MdDone />
    } else if (status === "uploading" ) {
        return <CircularProgress size="1em" color="secondary"/>
    }
    else {
        return <BiUpload />
     }
}

function renderIcon(type : string) {
    if (type === 'image') {
        return <BiCamera />
    } else if (type === 'video') {
        return <BiCameraMovie />
    } else {
        return <MdMicNone />
    }
}

function handleRemoveIconButton(dbObject : Object, setIsDeleting : Function,
                          dbItems : Array<Object>, setDBItems : Function) {
    setIsDeleting(true);
    dbDeleteElements(dbObject['type'], [dbObject['key']]).then(_ => {
        dbDeleteElements('data', dbObject['dataKeys']).then(_ => {
            setDBItems(dbItems.filter(dbItem => dbItem['key'] !== dbObject['key']));
            setIsDeleting(false);
        });
    });
}

function handlePrimaryAction(dbObject : Object, semaphore : Semaphore,
                             accessTokenFunction : Function, dispatch : Function) {

    if (dbObject['status'] !== 'uploading') {
        dbObject['status'] = 'uploading';
        upload(dbObject, semaphore, accessTokenFunction, dispatch);
    }
}

function renderSecondaryActions(dbObject : Object, semaphore : Semaphore,
                                accessTokenFunction : Function, dispatch : Function,
                                setIsDeleting : Function, dbItems : Array<Object>,
                                setDBItems : Function, isDeleting : boolean) {

    let status : string = dbObject['status'];
    return (
        <React.Fragment>
            <IconButton edge="end"
                        aria-label="action"
                        disabled={isDeleting || isItemDisabled(status)}
                        onClick={() => handlePrimaryAction(dbObject,
                                                           semaphore,
                                                           accessTokenFunction,
                                                           dispatch)}>
                {renderEndIcon(status)}
            </IconButton>
            <IconButton edge="end"
                        aria-label="delete"
                        disabled={isDeleting || dbObject['status'] === 'uploading'}
                        onClick={() => handleRemoveIconButton(dbObject,
                                                              setIsDeleting,
                                                              dbItems,
                                                              setDBItems)}>
                <MdClear />
            </IconButton>
        </React.Fragment>
    );
}

function UploadList(props) {
    const classes = useStyles();
    const { state, dispatch } = useContext(Store);
    const { semaphore } = state;

    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const {
        getAccessTokenSilently
    } = useAuth0();

    if (props.data.length === 0) {
        return;
    }

    return(
        props.data.sort((a : Object, b : Object) => (a['key'] < b['key']) ? 1 :  (b['key'] < a['key'] ? -1 : 0)).map((dbObject) =>
        <ListItem divider key={dbObject['key']} onClick={() => {}} disabled={isDeleting || isItemDisabled(dbObject['status'])}>
            <ListItemAvatar>
                <Avatar className={renderIconBackground(classes, dbObject['status'])}>
                    {renderIcon(dbObject['type'])}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={dbObject['filename']} secondary={'Status: ' + capitalize(dbObject['status'])} />
            <ListItemSecondaryAction>
                {renderSecondaryActions(dbObject, semaphore, getAccessTokenSilently, dispatch, setIsDeleting, props.data, props.setDBItems, isDeleting)}
            </ListItemSecondaryAction>
        </ListItem>
    ));
}

export default React.memo(UploadList);