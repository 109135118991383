import { uploadFile } from '../lib/media-requests';
import { openDB } from 'idb';
import { Semaphore } from 'await-semaphore';

const databaseName : string = 'mediaDB';
const databaseVersion : number = 1;

export function isDefined(obj) {
	return (obj !== null && obj !== undefined);
}

export function sleep(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

export function createTimestampString() {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000
    let fileName = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -5);

    return fileName;
}

export async function openDBWrapper() {
	let db = await openDB(databaseName, databaseVersion, {
        upgrade(db) {
            db.createObjectStore('image')
            db.createObjectStore('video')
            db.createObjectStore('audio')
            db.createObjectStore('data')
        }
    }).catch(error => {
    	console.error(error);
    	return null;
    });

    return db;
}

export async function dbPutElement(store : string, key : number, obj : Object) {
    let db;
    try {
        db = await openDBWrapper();

        if (db === null) return false;

        await db.put(store, obj, key);
        db.close();
    }
    catch (error) {
        if (db !== null) db.close();
        console.error(error);
        dbDeleteElements(store, [key]);
    }
    return true;
}

export async function dbDeleteElements(store : string, keys : Array<number>) {
    let db;
    try {
        db = await openDBWrapper();

        if (db === null) return false;

        for (let key of keys) {
            await db.delete(store, key);
        }

        db.close();
    }
    catch (error) {
        console.error(error);
        if (db !== null) db.close();
        return false;
    }
    return true;
}

export async function dbGetElement(store : string, key : number) {
    let result = null;
    let db;

    try {
        db = await openDBWrapper();
        if (db !== null) {
            let dbResult = await db.get(store, key);
            if (isDefined(dbResult)) {
                result = dbResult;
            }
            db.close();
        }
    }
    catch (error) {
        if (db !== null) db.close();
        console.error(error);
    }
    return result;
}

export async function dbGetAllElements() {
    let resultArray : Object[] = [];
    let db;

    try {
        db = await openDBWrapper();

        if (db !== null) {
			let images = await db.getAll('image');
            resultArray.push(...images);

            let videos = await db.getAll('video');
            resultArray.push(...videos);

            let audio = await db.getAll('audio');
            resultArray.push(...audio);
            db.close();
        }
    }
    catch (error) {
        if (db !== null) db.close();
        console.error(error);
    }

    return resultArray;
}

// Not called, will clean out old data from database if called.
export async function dbClearOldElements() {
    let db;
    let unixTimestamp = Date.now();
    let deleteTimestamp = unixTimestamp - (60 * 60 * 24 * 2); // 2 days

    const deleteFunc = async function(dbObjects : Array<Object>) {
        for (let dbObject of dbObjects) {
            if (dbObject['status']  === 'uploaded' && dbObject['key'] <= deleteTimestamp) {
                await db.delete(dbObject['type'], dbObject['key']);
            }
        }
    }

    try {
        db = await openDBWrapper();

        if (db !== null) {
            let images = await db.getAll('image');
            await deleteFunc(images);

            let videos = await db.getAll('video');
            await deleteFunc(videos);

            let audio = await db.getAll('audio');
            await deleteFunc(audio);
        }
        db.close();
    }
    catch (error) {
        if (db !== null) db.close();
        console.error(error);
    }
}

export async function upload(dbObject : Object, semaphore : Semaphore, accessTokenFunction : Function, dispatcherFunction : Function) {
    if (dbObject['status'] === 'uploaded') return;

    let release = await semaphore.acquire();
    try {
    	dispatcherFunction({type: 'SET_IS_UPLOADING', payload: true})

        let fileExtension = ".mp4";

        if (dbObject['type'] === 'image') {
            fileExtension = '.png'
        }

        dbObject['status'] = 'uploading';
        await dbPutElement(dbObject['type'], dbObject['key'], dbObject);
        dispatcherFunction({type: 'TOGGLE_UPLOAD_VIEW'})

        let auth0Token = await accessTokenFunction();
        let success = await uploadFile(auth0Token, dbObject, fileExtension);

        dbObject['status'] = (success) ? 'uploaded' : 'failed';
        await dbPutElement(dbObject['type'], dbObject['key'], dbObject);
        if (success) {
            await dbDeleteElements('data', dbObject['dataKeys']);
        }

        dispatcherFunction({type: 'TOGGLE_UPLOAD_VIEW'});
    	dispatcherFunction({type: 'SET_IS_UPLOADING', payload: false})
    	release();
    }
    catch (error) {
        console.error(error);
        release();
    }
}

export async function preview(dbObject : Object) : Promise<any> {
    let data = await dbGetElement('data', dbObject['key']);

    if (data !== null) {
        let tag = document.getElementById('preview');
        tag?.setAttribute('src', window.URL.createObjectURL(data));
    }
}

export async function removeData(history : any) {
	let dbElement : Object = history.location.state as Object;
	await dbDeleteElements(dbElement['type'], [dbElement['key']]);
	await dbDeleteElements('data', dbElement['dataKeys']);
	history.goBack();
}

export function uploadButton(history : any, semaphore : Semaphore, accessTokenFunction : Function, dispatcherFunction : Function) : void {
	let dbElement : Object = history.location.state as Object;
	upload(dbElement, semaphore, accessTokenFunction, dispatcherFunction);
	history.goBack();
}

export function toMedia(captureType : string, previousCaptureType : string, history : any, dispatcherFunction : Function) : void {
    dispatcherFunction({type: 'SET_PREVIOUS_CAPTURE_MODE', payload: previousCaptureType});
	dispatcherFunction({type: 'SET_CAPTURE_MODE', payload: captureType});
	history.push('/media')
}
