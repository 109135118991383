import React, { Dispatch, useReducer, createContext } from 'react';
import { Actions, initialState, IState, Reducer } from '../reducers/Reducer';

interface IContextProps {
    state : IState;
    dispatch : Dispatch<Actions>
}

export const Store = createContext({} as IContextProps);

export function StoreProvider(props : any) {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const value = { state, dispatch };
    return (
        <Store.Provider value={value}>{props.children}</Store.Provider>
    );
}