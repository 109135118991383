import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'
import { BiCircle, BiStopCircle, BiCamera, BiCameraMovie, BiUpload } from 'react-icons/bi'
import { IoIosReverseCamera } from 'react-icons/io'
import { MdMic, MdMicNone } from 'react-icons/md'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Fab from '@material-ui/core/Fab';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Duration from './Duration';
import LogoutButton from '../auth/logout-button';
import { createTimestampString, dbPutElement } from '../lib/util';
import { Store } from '../store/Store';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

declare const window : any;

var frontTriggered : boolean = false;
var addedEventListener : boolean = false;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    mediaRoot: {
        flexGrow: 1,
    },
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
    },
    fabRecord: {
        position: 'absolute',
        zIndex: 1,
        top: -50,
        left: 0,
        right: 0,
        margin: '0 auto',
    }
}),);

function oniOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function capture(captureMode : string, front : boolean, videoElement : HTMLVideoElement) : Promise<string> {
    return new Promise(function(resolve, reject) {
        let constraints : Object;

        if (captureMode === 'audio') {
            constraints = { video: false, audio: true };
        }
        else {
            constraints = {
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    frameRate: { ideal: 30 },
                    facingMode: (front ? "user" : "environment")
                },
                audio: true
            }
        }

        if (window.stream !== undefined) {
            const tracks = window.stream.getTracks();
            tracks.forEach(track => track.stop());
        }

        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
            window.stream = stream
            if (videoElement !== null) {
                if (videoElement.srcObject !== null) {
                    videoElement.srcObject = null
                }
                videoElement.srcObject = stream;

                var playPromise = videoElement.play();
                playPromise.then(_ => {
                    resolve();
                })
                .catch(error => {
                    reject("Could not stream data to the video preview");
                })
            } else {
                resolve();
            }
        })
        .catch((e) => {
            reject("Failed to return a stream");
        })
    })
}


function Media() {
    const ref = useRef<HTMLVideoElement>(null);
    const classes = useStyles();

    const { state, dispatch} = useContext(Store);
    const { captureMode, isUploading, previousCaptureMode } = state;

    const [recording, setRecording] = useState<boolean>(false);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
    const [front, setFront] = useState<boolean>(localStorage.getItem('front') === "true");
    const [captureLoading, setCaptureLoading] = useState<boolean>(false);


    const history = useHistory();

    const [loadingPreview, setLoadingPreview] = useState<boolean>(false);

    var dbElement : Object | null = null;

    async function takePhoto() {
        setLoadingPreview(true);

        if (ref.current) {
            let canvas : HTMLCanvasElement = document.createElement('canvas');
            canvas.width = ref.current.videoWidth;
            canvas.height = ref.current.videoHeight;

            let ctx = canvas.getContext('2d');
            if (ctx !== null && ctx !== undefined) {
                ctx.drawImage(ref.current, 0, 0, canvas.width, canvas.height);

                canvas.toBlob(function (blob) {
                    if (blob !== null) {
                        let unixTimestamp = Date.now();
                        let dbElement = {'status': 'pending', 'key' : unixTimestamp, 'type' : 'image', 'filename' : createTimestampString(), 'dataKeys' : [unixTimestamp]};
                        dbPutElement('image', unixTimestamp, dbElement).then(imageSuccess => {
                            if (imageSuccess) {
                                dbPutElement('data', unixTimestamp, blob).then(dataSuccess => {
                                    if (dataSuccess) {
                                        dispatch({ type: "SET_PREVIOUS_CAPTURE_MODE", payload: 'preview'});
                                        history.push('/preview/photo', dbElement);

                                    }
                                });
                            }
                        });
                    }
                });
            }
        }
    }

    async function handleDataAvailable(event : BlobEvent) {
        if (event.data && event.data.size > 0) {
            let unixTimestamp = Date.now();
            if (dbElement !== null) {
                dbElement['dataKeys'].push(unixTimestamp);
                dbElement['dataSize'] += event.data.size;
                dbElement['duration'] = event.timeStamp;
                let dbResult = await dbPutElement('data', unixTimestamp, event.data);
                if (!dbResult) {
                    // Stop recording?
                }
            }
        }
    }

    function handleMediaRecorderError() {
        console.error('Media Error')
    }

    function handleMediaRecorderStop() {
        if (dbElement !== null) {
            dbPutElement(captureMode, dbElement['key'], dbElement).then(dbResult => {
                if (dbResult) {
                    dispatch({ type: "SET_PREVIOUS_CAPTURE_MODE", payload: 'preview'});
                    history.push('/preview/recording', dbElement);
                }
            })
        }
    }

    function record() {
        if ((captureMode === "video" || captureMode === 'audio') && !recording) startRecording();
        if ((captureMode === "video" || captureMode === 'audio') && recording) stopRecording();
        if (captureMode === "photo") takePhoto();
    }

    function startRecording() {
        if (window.screen !== undefined && window.stream.active) {
            setRecording(true);
            try {
                let unixTimestamp = Date.now();
                dbElement = {'status' : 'pending', 'key' : unixTimestamp, 'type' : captureMode, 'filename' : createTimestampString(), 'dataSize' : 0, 'dataKeys' : [], 'duration' : 0};

                var mr = new MediaRecorder(window.stream);
                mr.ondataavailable = handleDataAvailable;
                mr.onerror = handleMediaRecorderError;
                mr.onstop = handleMediaRecorderStop;
                mr.start(60000);
                setMediaRecorder(mr);

            } catch (error) {
                console.error('MediaRecorder error:', error);
                setRecording(false);

                let errorMsg = 'MediaRecorder not supported.';
                if (oniOS()) {
                    errorMsg += '\n\nEnable MediaRecorder in:\n\nSettings->Safari->Advanced->"Experimental Features"->MediaRecorder';
                }
                alert(errorMsg);
            }
        }
    }

    function stopRecording() {
        setLoadingPreview(true);
        mediaRecorder?.stop();
    }

    function handleUploadSelected() {
        if (!recording) {
            dispatch({ type: "SET_PREVIOUS_CAPTURE_MODE", payload: captureMode});
            dispatch({ type: "SET_CAPTURE_MODE", payload: 'upload'});
            history.push('/upload');
        }
    }

    function handleNavigationSelection(event) {
        if (!recording && event.currentTarget !== null) {
            let mode : string = event.currentTarget.id;
            dispatch({ type: "SET_PREVIOUS_CAPTURE_MODE", payload: captureMode});
            dispatch({ type: "SET_CAPTURE_MODE", payload: mode});
            localStorage.setItem('captureMode', mode)
        }
    }

    function cameraSwitchClick() {
        if (!frontTriggered){
            setCaptureLoading(true);
            frontTriggered = true;
            setFront(!front);
            localStorage.setItem('front', JSON.stringify(!front));
        }
    }

    useEffect(() => {
        var videoElement : HTMLElement = document.getElementById('videoTag') as HTMLElement;
        var audioGridElement : HTMLElement = document.getElementById('audioGrid') as HTMLElement;
        if (videoElement !== null) {
            disableBodyScroll(videoElement);
        } else if (audioGridElement !== null) {
            disableBodyScroll(audioGridElement);
        }

        if (frontTriggered) {
            frontTriggered = false;

            capture(captureMode, front, ref.current as HTMLVideoElement).then(_ => {
                setCaptureLoading(false);
            })
        }
        else if (previousCaptureMode !== captureMode && !loadingPreview) {
            if (previousCaptureMode === 'init') {
                setCaptureLoading(true);
                capture(captureMode, front, ref.current as HTMLVideoElement).then(_ => {
                    setCaptureLoading(false);
                    dispatch({ type: "SET_PREVIOUS_CAPTURE_MODE", payload: captureMode});
                });
            }
            else if (previousCaptureMode === 'preview' || previousCaptureMode === 'upload' || previousCaptureMode === 'audio') {
                setCaptureLoading(true);
                capture(captureMode, front, ref.current as HTMLVideoElement).then(_ => {
                    setCaptureLoading(false);
                })
                .catch(reason => {
                    setCaptureLoading(false);
                })
            }
            else if ((previousCaptureMode === 'photo' || previousCaptureMode === 'video') && captureMode === 'audio') {
                setCaptureLoading(true);
                capture(captureMode, front, ref.current as HTMLVideoElement).then(_ => {
                    setCaptureLoading(false);
                }).catch(reason => {
                    setCaptureLoading(false);
                })
            }
        }

        // Special case on iOS when installed as PWA app and the app resumes, we have to do capture again.
        if (!addedEventListener) {
            if (oniOS() && window.navigator.standalone){
                document.addEventListener('visibilitychange', function() {
                  if (document.visibilityState === 'visible') {
                      setCaptureLoading(true);
                      capture(captureMode, front, ref.current as HTMLVideoElement).then(_ => {
                          setCaptureLoading(false);
                      }).catch(reason => {
                          setCaptureLoading(false);
                          console.log(reason);
                      });
                  }
                });
                addedEventListener = true;
            }
        }

        return () => {
            clearAllBodyScrollLocks();
        }

        // front: capture will be called when front changes in cameraSwitchClick
        // ref.current we do not want to trigger useEffect when ref.current changes.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [captureMode, previousCaptureMode, front])

    function renderAudio() {
        if (recording && !loadingPreview) {
            return (
                <div className="recordingMic">
                    <MdMic size="4em"/>
                </div>
            )
        } else {
            return <MdMicNone size="4em"/>
        }
    }

    function renderRecordButton() {
        if (loadingPreview) {
            return <CircularProgress color="secondary" size="1.5em"/>
        }
        else if (recording) {
            return <BiStopCircle size="2em"/>
        }
        else {
            return <BiCircle size="2em"/>
        }
    }

    return (
        <div className={classes.mediaRoot}>
                <React.Fragment>
                    {!recording &&
                        <AppBar position="fixed" color="transparent">
                            <Toolbar variant="dense">
                                <Grid container justify="space-between">
                                    <LogoutButton/>
                                    {captureMode !== 'audio' &&
                                        <Button variant="contained" color="primary" size="small" disabled={captureLoading} onClick={cameraSwitchClick}><IoIosReverseCamera size="1.5em"/></Button>
                                    }
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    }
                    {captureMode === 'audio' ?
                        <Grid container direction="column" justify="center" alignItems="center" style={{ minHeight: '100vh' }}>
                            <Grid container direction="column" justify="center" alignItems="center" id="audioGrid">
                                <div style={{paddingBottom: 20}}>
                                    {renderAudio()}
                                </div>
                                <Duration recording={recording} isLoadingPreview={loadingPreview}/>
                            </Grid>
                        </Grid>

                        : <video id='videoTag' style={{width: '100%', margin: 0, padding: 0}} ref={ref} autoPlay playsInline muted></video>
                    }
                    <AppBar position="fixed" color="inherit" className={classes.bottomAppBar}>
                        <Toolbar variant="dense">
                            <Fab color="secondary" aria-label="add" className={classes.fabRecord} onClick={record} disabled={loadingPreview || captureLoading}>
                                {renderRecordButton()}
                            </Fab>
                            <Grid container>
                                <Grid container direction="row" justify="space-around">
                                    <Button variant={captureMode === 'photo' ? "contained" : "outlined"} color="primary" size="large" onClick={handleNavigationSelection} id="photo" disabled={loadingPreview}><BiCamera/></Button>
                                    <Button variant={captureMode === 'video' ? "contained" : "outlined"} color="primary" size="large" onClick={handleNavigationSelection} id="video" disabled={loadingPreview}><BiCameraMovie /></Button>
                                    <Button variant={captureMode === 'audio' ? "contained" : "outlined"} color="primary" size="large" onClick={handleNavigationSelection} id="audio" disabled={loadingPreview}><MdMicNone /></Button>
                                    <div style={{width: '4em', height: '2em'}}>
                                        <Button variant="outlined" color="secondary" size="large" onClick={handleUploadSelected} id="upload" disabled={loadingPreview}><BiUpload /></Button>
                                        {isUploading &&
                                            <div style={{position: 'relative', bottom: 4, zIndex: 2}}>
                                                <LinearProgress color="secondary"/>
                                            </div>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </React.Fragment>

        </div>
    );
}

export default Media;
