import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BiUpload } from 'react-icons/bi';
import { MdClear } from 'react-icons/md';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useAuth0 } from '@auth0/auth0-react';
import { upload, preview, removeData } from '../lib/util';
import { Store } from '../store/Store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
    previewRoot: {
        flexGrow: 1,
    },
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
    },
    fabUpload: {
        position: 'absolute',
        zIndex: 1,
        top: -40,
        left: 'auto',
        right: 15,
        margin: '0 auto',
    },
    fabDelete: {
        position: 'absolute',
        zIndex: 1,
        top: -40,
        right: 'auto',
        left: 15,
        margin: '0 auto',
    },
}),);

function PhotoPreview() {
    const classes = useStyles();
    const history = useHistory();

    const [isRemoving, setIsRemoving] = useState<boolean>(false);

    const { state, dispatch } = useContext(Store);
    const { semaphore } = state;

    const {
        getAccessTokenSilently
    } = useAuth0();

    function uploadButtonHandler() {
        let imageElement : Object = history.location.state as Object;
        upload(imageElement, semaphore, getAccessTokenSilently, dispatch);
        history.goBack();
    }

    useEffect(() => {
        preview(history.location.state as Object);
    }, [history.location.state]);

    return (
        <div className={classes.previewRoot}>
            <React.Fragment>
                <img id="preview" style={{ width: '100%', margin: 0, padding: 0}} alt="preview"></img>
                <AppBar position="fixed" color="inherit" className={classes.bottomAppBar}>
                    <Toolbar variant="dense">
                        <Grid container>
                            <Grid container direction="row" justify="space-between">
                                <Button variant="outlined" color="secondary" disabled={isRemoving} onClick={() => {setIsRemoving(true); removeData(history)}} size="small" endIcon={isRemoving ? <CircularProgress color="secondary" size="0.7em" /> : <MdClear />}>Remove</Button>
                                <Button variant="contained" color="secondary" disabled={isRemoving} onClick={uploadButtonHandler} size="small" endIcon={<BiUpload />}>Upload</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        </div>
    );
}

export default PhotoPreview;
