import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from '@material-ui/core/CircularProgress';

const LoginButton = (props) => {
  const { loginWithRedirect } = useAuth0();
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  return (
    <Button
      color="primary"
      variant="contained"
      id="loginButton"
      style={{width: "100%"}}
      disabled={isRedirecting}
      onClick={() => {setIsRedirecting(true); loginWithRedirect()}}
      endIcon={isRedirecting ? <CircularProgress size="1em" /> : null}
    >
      Log In
    </Button>
  );
};

export default LoginButton;
