import { htmlPOST, htmlGET, htmlPUT } from '../lib/html-methods';
import { loginAndGetJwtToken } from '../lib/login-requests';
import { isDefined, sleep, dbGetElement} from '../lib/util';
import { baseUrl } from '../config';

async function makeCallWithRetries(func : Function, ...args) {
	let retries : number = 0;
	let sleepTime : Array<number> = [1000, 3000, 5000, 10000, 0];

	while (retries < 5) {
		try {
			let res : any = await func(...args);
			return res
		}
		catch (error) {
			console.error(error);
			await sleep(sleepTime[retries++]);
		}
	}
	throw new Error(func.name + " failed");
}

async function uploadImage(dbObject : Object, jwtToken : string, folderId : string, fileContainerId : string) {
	let filename = dbObject['filename'];
	let postData = {"name" : filename + '.png', "primary" : true};

	let fileId = await makeCallWithRetries(createFileInContainer, jwtToken, folderId, fileContainerId, postData);
	let dbResponse = await dbGetElement('data', dbObject['dataKeys'][0]);

	if (dbResponse !== null) {
		let result = await makeCallWithRetries(uploadFileToServer, jwtToken, folderId, fileContainerId, fileId, dbResponse, dbResponse['type']);

		if (result) {
			return true;
		}
	}
	else {
		throw new Error("Database error");
	}
}

async function uploadVideoAudioFile(dbObject : Object, jwtToken : string, folderId : string, fileContainerId : string) {
	let filename = dbObject['filename'];
	let keys : Array<number> = dbObject['dataKeys'];
	let fileIds : Array<string> = [];

	// Get fileId for all segments
	for (let i = 0; i < keys.length; ++i) {
		let postData = {'name' : filename + '_part' + i.toString() + '.blobpart', 'primary' : false, 'type' : 'segment'};
		let fileId = await makeCallWithRetries(createFileInContainer, jwtToken, folderId, fileContainerId, postData);
		fileIds.push(fileId);
		await sleep(100); // Don't overload the poor shaky server.
	}

	let dbData = null;

	// Upload the actual segments
	for (let i = 0; i < keys.length; ++i) {
		let key = keys[i];

		dbData = await dbGetElement('data', key);

		if (dbData !== null) {
			let result = await makeCallWithRetries(uploadFileToServer, jwtToken, folderId, fileContainerId, fileIds[i], dbData, dbData['type']);
			if (!result) {
				throw new Error('Failed to upload: ' + fileIds[i].toString());
			}

		}
		else {
			throw new Error('Failed to get ' + key.toString() + ' from database.');
		}

		dbData = null;
		await sleep(100); // Don't overload the poor shaky server.
	}

	// Upload manifest file
	let postData = {'name' : filename + '_manifest.json', 'primary' : true, 'type' : dbObject['type']};
	let manifestBody = JSON.stringify(fileIds);

	let fileId = await makeCallWithRetries(createFileInContainer, jwtToken, folderId, fileContainerId, postData);
	let result = await makeCallWithRetries(uploadFileToServer, jwtToken, folderId, fileContainerId, fileId, manifestBody, 'application/json');

	if (result) {
		return true;
	}
	else {
		return false;
	}
}

export async function uploadFile(auth0Token : string, dbObject : Object, fileExtension : string) {
	try {
		let filename = dbObject['filename'];

		let jwtToken = await makeCallWithRetries(loginAndGetJwtToken, auth0Token);
		let folderId = await makeCallWithRetries(getPrimaryFolderId, jwtToken);
		let fileContainerId = await makeCallWithRetries(addFileContainer, jwtToken, folderId, filename);

		if (dbObject['type'] === 'image') {
			let result = await uploadImage(dbObject, jwtToken, folderId, fileContainerId);
			return result;
		}
		else {
			let result = await uploadVideoAudioFile(dbObject, jwtToken, folderId, fileContainerId);
			return result;
		}
	}
	catch(error) {
		console.error(error);
		return false;
	}
}

async function getPrimaryFolderId(jwtToken : string) {
	return htmlGET(baseUrl + "/api/media/folders/my_primary_folder", "Bearer " + jwtToken)
	.then(response => {
		if (response.status === 200) {
			return response.json().then(data => {
				if (isDefined(data) && isDefined(data.data) && isDefined(data.data.id) && data.data.id !== "") {
					return data.data.id;
				}
				else {
					throw new Error("Parsing error in getPrimaryFolderId");
				}
			});
		}
		else {
			throw new Error("Got status code " + response.status.toString());
		}
	});
}

async function addFileContainer(jwtToken : string, folderId : string, fileName : string) {
	return htmlPOST(baseUrl + "/api/media/folders/" + folderId + "/file_containers", {"name" : fileName}, "Bearer " + jwtToken).then(response => {
		if (response.status === 201) {
			return response.json().then(data => {
				if (isDefined(data) && isDefined(data.data) && isDefined(data.data.id) && data.data.id !== "") {
					return data.data.id;
				}
				else {
					throw new Error("Parsing error in addFileContainer");
				}
			});
		}
		else {
			throw new Error("Got status code " + response.status.toString());
		}
	});
}

async function createFileInContainer(jwtToken : string, folderId : string, fileContainerId, data : Object) {
	return htmlPOST(baseUrl + "/api/media/folders/" + folderId + "/file_containers/" + fileContainerId + "/files", data, "Bearer " + jwtToken).then(response => {
		if (response.status === 201) {
			return response.json().then(data => {
				if (isDefined(data) && isDefined(data.data) && isDefined(data.data.id) && data.data.id !== "") {
					return data.data.id;
				}
				else {
					throw new Error("Parsing error in createFileInContainer");
				}
			});
		}
		else {
			throw new Error("Got status code " + response.status.toString());
		}
	});
}

async function uploadFileToServer(jwtToken : string, folderId : string, fileContainerId : string, fileId : string, data : Blob, type : string) {
	return htmlPUT(baseUrl + "/api/media/folders/" + folderId + "/file_containers/" + fileContainerId + "/files/" + fileId + "/upload", data, "Bearer " + jwtToken, type).then(response => {
		if (response.status === 200) {
			return true;
		}
		else {
			throw new Error("Got status code " + response.status.toString());
		}
	});
}
