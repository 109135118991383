import React, { useState, useEffect, useRef } from 'react'

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

function stop(timer : any) : void {
    clearInterval(timer);
}

function start(duration : number, setDuration : Function) : any {
    var start : number;
    if (duration === 0) {
        start = Date.now();
    } else {
        start = Date.now() - duration;
    }

    return setInterval(() => {
        setDuration(Date.now() - start)
    }, 1000)
}

function Duration(props) {
    const [duration, setDuration] = useState<number>(0);
    const previousProps : any = usePrevious(props);

    let seconds = ("0" + (Math.floor(duration / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(duration / 60000) % 60)).slice(-2);
    let hours = ("0" + Math.floor(duration / 3600000)).slice(-2);

    useEffect(() => {
        var timer : any = 0;

        if (previousProps !== undefined && previousProps !== null) {
            if (props.recording && !props.loadingPreview) {
                timer = start(duration, setDuration);
            }
            else if ((!props.recording && previousProps.recording) || props.isLoadingPreview) {
                stop(timer);
            }
        }
        return () => {
            clearInterval(timer);
        }
    }, [props, duration, previousProps]);

    return(
        <div>
            <h1>{hours}:{minutes}:{seconds}</h1>
        </div>
    );
}

export default Duration;