import { htmlGET } from './lib/html-methods';

export const baseUrl : string = (window.location.protocol + '//' + window.location.host.split('.').slice(1).join('.'));

export async function authConfig() {
    try {
        const response = await htmlGET(baseUrl + '/api/identity/auth0/config');
        if (response.status !== 200) {
            throw new Error("Status " + response.status.toString());
        }

        return response.json();
    } catch(e) {
        throw new Error("Auth0 config request failed: " + e.message);
    }
}
